<style>
@media (max-width: 1024px) {
  .responsive-table {
    min-width: 1000px !important;
  }
}
</style>

<template>
  <div class="overflow-auto" style="max-height: 75vh">
    <table
      class="min-w-full divide-y divide-gray-300 responsive-table sticky top-0 z-30"
    >
      <thead>
        <tr :class="`grid grid-cols-${columns.length}`">
          <th :class="`col-span-${columns.length - projectionColumns.length}`"></th>
          <th
            :class="`col-span-${projectionColumns.length} px-3 my-0 text-xs text-gray-900 text-center`"
          >
            Projection {{ currentMonth }}
          </th>
        </tr>
        <tr :class="`grid grid-cols-${columns.length}`">
          <TableHeader
            v-for="column in columns"
            :key="column.key"
            :sortColumn="sortColumn"
            :sortOrder="sortOrder"
            :style="column.style"
            :columnKey="column.key"
            @sort="handleSort"
          >
            {{ column.label }}
          </TableHeader>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr
          v-for="row in rows"
          :key="row.name"
          :class="`grid grid-cols-${columns.length} ${rowClass(row)}`"
          @click="handleRowClick(row)"
        >
          <td
            :class="`break-words overflow-hidden flex items-center py-2 pl-2 text-xs font-medium text-gray-900 sticky left-0 ${rowClass(row)}`"
          >
            {{ row.name }}
          </td>
          <td
            class="flex items-center justify-center px-2 py-2 text-xs text-gray-500"
          >
            {{ row.salesCount }}
          </td>
          <td
            class="flex items-center justify-center px-2 py-2 text-xs text-gray-500"
          >
            {{ row.activeSalesCount }}
          </td>
          <td
            class="flex items-center justify-center px-2 py-2 text-xs text-gray-500"
          >
            {{ row.cancellationRate }} %
          </td>
          <td
            v-if="showTargetColumns"
            class="flex items-center justify-center px-2 py-2 text-xs text-gray-500"
          >
            {{ row.salesCountTarget }}
          </td>
          <td
            v-if="showTargetColumns"
            class="flex items-center justify-center px-2 py-2 text-xs text-gray-500"
          >
            <TargetCell :value="row.salesCountResultTarget"/>
          </td>
          <td
            class="flex items-center justify-center px-2 py-2 text-xs text-gray-500"
            style="border-left: 4px solid #ccc;"
          >
            {{ row.salesCountProjection }}
          </td>
          <td
            class="flex items-center justify-center px-2 py-2 text-xs text-gray-500"
            style="border-right: 4px solid #ccc;"
          >
            <VariationCell :value="row.salesCountProjectionVariation" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableHeader from "@/components/analytics/retail/utils/TableHeader.vue";
import TargetCell from "@/components/analytics/retail/utils/TargetCell.vue";
import VariationCell from "@/components/analytics/retail/utils/VariationCell.vue";

export default {
  components: {
    TableHeader,
    TargetCell,
    VariationCell,
  },

  props: {
    stats: {
      type: Array,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
    currentMonth: {
      type: String,
      required: true,
    },
    statsClickable: {
      type: Boolean,
      default: false,
    },
    showTargetColumns: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      sortColumn: null,
      sortOrder: null,
      commonColumns: [
        {
          key: "name",
          label: "Nom",
        },
        {
          key: "sales_count",
          label: "Contrats vendus",
          style: "text-align: center;",
        },
        {
          key: "active_sales_count",
          label: "Contrats actifs",
          style: "text-align: center;",
        },
        {
          key: "cancellation_rate",
          label: "Taux de résiliation",
          style: "text-align: center;",
        }
      ],
      targetColumns: [
        {
          key: "sales_count_target",
          label: "Objectif",
          style: "text-align: center;",
        },
        {
          key: "sales_count_result_target",
          label: "Atteinte de l’objectif",
          style: "text-align: center;",
        },
      ],
      projectionColumns: [
        {
          key: "sales_count_projection",
          label: "Contrats vendus",
          style: "text-align: center; border-left: 4px solid #ccc;",
        },
        {
          key: "sales_count_projection_variation",
          label: "Évolution M-1",
          style: "text-align: center; border-right: 4px solid #ccc;",
        },
      ],
    };
  },

  computed: {
    columns() {
      return [
        ...this.commonColumns,
        ...(this.showTargetColumns ? this.targetColumns : []),
        ...this.projectionColumns,
      ];
    },

    statsRows() {
      return this.stats || [];
    },

    totalRow() {
      return [this.total]
    },

    rows() {
      return [
        ...this.totalRow.map((row) => ({ ...row, clickable: false })),
        ...this.statsRows.map((row) => ({ ...row, clickable: this.statsClickable, body: true }))
      ];
    },
  },

  methods: {
    handleRowClick(row) {
      if (!row.clickable) return;

      this.$emit("rowClicked", row);
    },

    handleSort(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }

      this.emitSortEvent();
    },

    emitSortEvent() {
      this.$emit("sortChanged", {
        column: this.sortColumn,
        order: this.sortOrder,
      });
    },

    rowClass(row) {
      if (row.clickable) return 'bg-white hover:bg-gray-50 cursor-pointer';
      if (row.body) return 'bg-white';

      return 'bg-gray-100';
    }
  },
};
</script>
