<template>
  <div>
    <ClaimList
      v-bind:claims="this.claims"
      v-bind:current-page="this.currentPage"
      v-bind:total-number-of-claims="this.totalNumberOfClaims"
      @redirectToClaimDetailsPage="this.redirectToClaimDetailsPage"
    />
    <Pagination
      v-bind:currentPage="currentPage"
      v-bind:pages="pages"
      v-bind:startIdx="startIdx"
      v-bind:endIdx="endIdx"
      v-bind:total="this.totalNumberOfClaims"
      v-on:changePage="this.handleChangePage"
      v-if="pages.length > 1"
    />
  </div>
</template>

<script>
import ClaimList from "@/components/claim/list/ClaimList.vue";
import Pagination from "@/components/utils/Pagination.vue";
const PAGE_SIZE = 25;
export default {
  components: {
    ClaimList,
    Pagination,
  },

  props: ["claims", "currentPage", "totalNumberOfClaims"],

  computed: {
    pages: function () {
      if (this.totalNumberOfClaims < PAGE_SIZE) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.totalNumberOfClaims / PAGE_SIZE)).keys(),
      ].map((e) => e + 1);
    },

    startIdx: function () {
      return (this.currentPage - 1) * PAGE_SIZE;
    },

    endIdx: function () {
      return Math.min(this.startIdx + PAGE_SIZE, this.claimsCount);
    },

    claimsCount: function () {
      return this.claims.length;
    },
  },

  methods: {
    handleChangePage: function (page) {
      this.$emit("changePage", page);
    },

    redirectToClaimDetailsPage: function (claimId) {
      this.$emit("redirectToClaimDetailsPage", claimId);
    },
  },
};
</script>
