<template>
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <PageHeaderTitle title="Products" />
    </div>
    <Menu
      v-if="this.anyProductSelected"
      as="div"
      class="relative inline-block text-left z-20"
    >
      <div>
        <MenuButton
          class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          Bulk Actions
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute overflow-y-auto right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto"
        >
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <button
                @click="this.uploadProductsToPlatform"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Upload to Platform<ArrowUpIcon
                    class="h-5 w-5 ml-2 text-primary-dark"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                @click="this.approveProducts"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Approve Products<CheckIcon
                    class="h-5 w-5 ml-2 text-green-400"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                @click="this.unapproveProducts"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Unapprove Products<XIcon
                    class="h-5 w-5 ml-2 text-red-400"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                @click="this.matchProducts"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Match Products<DocumentIcon
                    class="h-5 w-5 ml-2 text-yellow-400"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                @click="this.unmatchProducts"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Unmatch Products<DocumentIcon
                    class="h-5 w-5 ml-2 text-red-400"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                @click="this.unpublishProducts"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Unpublish Products<StopIcon
                    class="h-5 w-5 ml-2 text-red-400"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                @click="this.approveMatchAndPublishProducts"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Approve, Match, Publish<ChevronDoubleRightIcon
                    class="h-5 w-5 ml-2 text-primary-dark"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                @click="this.approveAndMatchProducts"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                  'w-full',
                ]"
              >
                <p class="flex">
                  Approve, Match<ChevronDoubleRightIcon
                    class="h-5 w-5 ml-2 text-yellow-500"
                    aria-hidden="true"
                  />
                </p>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import PageHeaderTitle from "@/components/utils/PageHeaderTitle.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ArrowUpIcon,
  CheckIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  DocumentIcon,
  StopIcon,
  XIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    PageHeaderTitle,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ArrowUpIcon,
    CheckIcon,
    XIcon,
    DocumentIcon,
    StopIcon,
    ChevronDoubleRightIcon,
  },
  props: ["selectedProducts"],
  computed: {
    anyProductSelected: function () {
      return this.selectedProducts.length > 0;
    },
  },
  methods: {
    uploadProductsToPlatform() {
      this.$emit("upload", this.selectedProducts);
    },
    matchProducts() {
      this.$emit("match", this.selectedProducts);
    },
    approveProducts() {
      this.$emit("approve", this.selectedProducts);
    },
    unapproveProducts() {
      this.$emit("unapprove", this.selectedProducts);
    },
    unmatchProducts() {
      this.$emit("unmatch", this.selectedProducts);
    },
    unpublishProducts() {
      this.$emit("unpublish", this.selectedProducts);
    },
    approveMatchAndPublishProducts() {
      this.$emit("approve-match-publish", this.selectedProducts);
    },
    approveAndMatchProducts() {
      this.$emit("approve-match", this.selectedProducts);
    },
  },
};
</script>
