<template>
  <div
    v-if="value != null"
    :class="`inline-block px-2 py-1 rounded-xl ${valueClass}`"
  >
    {{ variationFormatted }} %
  </div>
</template>

<script>
import { formatVariation } from "@/utils/variation_formatter";

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    dashed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    variationFormatted() {
      return formatVariation(this.value);
    },
    valueClass() {
      if (this.variationFormatted > 0 || this.variationFormatted === "+ ∞")
        return `bg-green-100 text-green-500 border border-dashed border-green-300`;
      if (this.variationFormatted < 0)
        return `bg-red-100 text-red-500 border border-dashed border-red-300`;

      return `bg-gray-100 text-gray-500 border border-dashed border-gray-300`;
    },
  },
};
</script>
