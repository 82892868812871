<template>
  <Modal :open="open" @close="close">
    <div>
      <h1 class="text-center font-extrabold text-xl">
        Modifier les informations du contrat
      </h1>
      <div>
        <p
          class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900 font-bold"
        >
          {{ $t("Plan Sale") }}
        </p>
        <div class="flex-1 mt-1">
          <label
            for="planSaleDate"
            class="flex items-center text-sm text-gray-700"
            >Date de début du contrat</label
          >
          <input
            type="date"
            id="planSaleDate"
            class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="01/01/1970"
            v-model="localPlanSale.date"
          />
        </div>
      </div>
      <div class="mt-4">
        <p
          class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900 font-bold"
        >
          {{ $t("Customer") }}
        </p>
        <div class="flex-1 mt-1">
          <label
            for="planSaleCustomerEmail"
            class="flex items-center text-sm text-gray-700"
            >Email</label
          >
          <input
            type="email"
            id="planSaleCustomerEmail"
            class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            v-model="localPlanSale.customer.email"
          />
        </div>
        <div class="flex-1 mt-1">
          <label
            for="planSaleCustomerInformationLastName"
            class="flex items-center text-sm text-gray-700"
            >Nom</label
          >
          <input
            type="text"
            id="planSaleCustomerInformationLastName"
            class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            v-model="localPlanSale.customer_information.last_name"
          />
        </div>
        <div class="flex-1 mt-2">
          <label
            for="planSaleCustomerInformationFirstName"
            class="flex items-center text-sm text-gray-700"
            >Prénom</label
          >
          <input
            type="text"
            id="planSaleCustomerInformationFirstName"
            class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            v-model="localPlanSale.customer_information.first_name"
          />
        </div>
        <div class="flex-1 mt-2">
          <label
            for="planSaleCustomerInformationPhone"
            class="flex items-center text-sm text-gray-700"
            >Numéro de téléphone</label
          >
          <input
            type="tel"
            id="planSaleCustomerInformationPhone"
            class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            v-model="localPlanSale.customer_information.phone"
          />
        </div>
        <div class="flex-1 mt-2">
          <GoogleAddress
            class="flex-1"
            :initialAddress="customerAddress"
            @newAddress="updateCustomerAddress"
          />
        </div>
      </div>
      <div class="mt-4">
        <p
          class="text-xl font-heading-1 pb-2 tracking-tight text-gray-900 font-bold"
        >
          {{ $t("Products") }}
        </p>
        <div
          v-for="product in localPlanSale.plan_sale_product"
          :key="product.id"
          class="mt-2"
        >
          <div class="flex-1 mt-1">
            <label
              :for="'planSaleProductTitle-' + product.id"
              class="flex items-center text-sm text-gray-700"
              >Title</label
            >
            <input
              type="text"
              :id="'planSaleProductTitle-' + product.id"
              class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
              v-model="product.title"
            />
          </div>
          <div class="flex-1 mt-2">
            <label
              :for="'planSaleProductReferenceId-' + product.id"
              class="flex items-center text-sm text-gray-700"
              >Reference ID</label
            >
            <input
              type="text"
              :id="'planSaleProductReferenceId-' + product.id"
              class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
              v-model="product.reference_id"
            />
          </div>
          <div class="flex-1 mt-2">
            <label
              :for="'planSaleProductPrice' + product.id"
              class="flex items-center text-sm text-gray-700"
              >Prix</label
            >
            <input
              type="number"
              :id="'planSaleProductPrice' + product.id"
              class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
              aria-describedby="price-currency"
              v-model="product.price"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 flex justify-center">
        <GenericButton @onClick="updatePlanSale"
          >Modifier le contrat</GenericButton
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import GoogleAddress from "@/components/address/GoogleAddress";

export default {
  components: {
    Modal,
    GenericButton,
    GoogleAddress,
  },

  props: ["open", "planSale"],

  data() {
    return {
      localPlanSale: {},
    };
  },

  mounted() {
    this.localPlanSale = {
      status: this.planSale.status,
      date: this.convertDate(this.planSale.startDate),
      customer: {
        email: this.planSale.customer.email,
      },
      customer_information: {
        first_name: this.planSale.customer.firstName,
        last_name: this.planSale.customer.lastName,
        phone: this.planSale.customer.phone,
        address1: this.planSale.customer.address1,
        city: this.planSale.customer.city,
        province: this.planSale.customer.province,
        country: this.planSale.customer.country,
        zip_code: this.planSale.customer.zipCode,
      },
      plan_sale_product: this.planSale.product.map((product) => ({
        id: product.id,
        title: product.title,
        reference_id: product.referenceId,
        price: product.price,
      })),
    };
  },

  computed: {
    customerAddress() {
      return {
        address: `${this.planSale.customer.address1}, ${this.planSale.customer.city}, ${this.planSale.customer.province}, ${this.planSale.customer.country}`,
        city: this.planSale.customer.city,
        province: this.planSale.customer.province,
        country: this.planSale.customer.country,
        zipCode: this.planSale.customer.zipCode,
      };
    },
  },

  methods: {
    updatePlanSale() {
      this.$emit("updatePlanSale", this.localPlanSale);
    },

    close() {
      this.$emit("closeUpdateModal");
    },

    updateCustomerAddress(newAddress) {
      this.localPlanSale.customer_information = {
        ...this.localPlanSale.customer_information,
        address1: newAddress.street,
        city: newAddress.city,
        province: newAddress.province,
        country: newAddress.country,
        zip_code: newAddress.zipCode,
      };
    },

    convertDate(dateStr) {
      // Convert date from format "dd/mm/yyyy" to format "yyyy-MM-dd"
      if (dateStr) {
        const parts = dateStr.split("/");
        if (parts.length === 3) {
          return `${parts[2]}-${parts[1]}-${parts[0]}`;
        }
      }
      return null;
    },
  },
};
</script>
