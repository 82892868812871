<template>
  <div>
    <ChallengeListContent :challenges="challenges" />
  </div>
</template>

<script>
import ChallengeListContent from "@/views/challenges/list/ChallengeListContent.vue";

export default {
  components: {
    ChallengeListContent,
  },
  props: {
    challenges: {
      type: Array,
      required: true,
    },
  },
};
</script>
