<template>
  <CollapsableContent :title="`Verticales proposées (${businessVerticals.length})`" :open="false">
    <template #header>
      <GenericButton @click="displayBusinessVerticalModalForm = true">
        Ajouter
      </GenericButton>

      <Modal v-bind:open="displayBusinessVerticalModalForm" @close="displayBusinessVerticalModalForm = false">
        <BusinessVerticalForm :store="store" @created="onBusinessVerticalCreated"/>
      </Modal>
    </template>
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ $t("Name") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ $t("Vertical") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ $t("Merchant commission") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ $t("Insurer commission") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ $t("Estaly commission") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ $t("Store additional fee commission") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ $t("Marketing page") }}
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {{ $t("Insurer") }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200 text-sm">
          <tr
            v-for="(insuranceOffer, index) in businessVerticals"
            :key="index"
            v-bind:class="'hover:bg-gray-100'"
          >
            <td class="px-3 py-3.5 font-bold">
              {{ $t(insuranceOffer.name) }}
            </td>
            <td class="px-3 py-3.5">
              {{ $t(insuranceOffer.vertical) }}
            </td>
            <td class="px-3 py-3.5">
              <FieldSpan
                v-bind:style-to-add="'bg-green-200'"
                v-bind:value="insuranceOffer.commissions.merchant"
              />
            </td>
            <td class="px-3 py-3.5">
              <FieldSpan
                v-bind:style-to-add="'bg-yellow-200'"
                v-bind:value="insuranceOffer.commissions.insurer"
              />
            </td>
            <td class="px-3 py-3.5">
              <FieldSpan
                v-bind:style-to-add="'bg-purple-200'"
                v-bind:value="insuranceOffer.commissions.estaly"
              />
            </td>
            <td class="px-3 py-3.5">
              <FieldSpan
                v-if="insuranceOffer.commissions.merchantOnAdditionalFee"
                v-bind:style-to-add="'bg-green-200'"
                v-bind:value="
                  insuranceOffer.commissions.merchantOnAdditionalFee
                "
              />
              <p class="font-bold" v-else>—</p>
            </td>
            <td class="px-3 py-3.5">
              <a
                v-bind:href="insuranceOffer.marketingPage"
                alt="marketing page"
                target="_blank"
                class="text-blue-400 underline"
                >Lien</a
              >
            </td>
            <td class="px-3 py-3.5">
              {{ insuranceOffer.insurer }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </CollapsableContent>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import Modal from "@/components/modal/Modal.vue";
import BusinessVerticalForm from "@/components/store/BusinessVerticalForm.vue";
import BackofficeService from "@/api/services/backoffice.js";

export default {
  components: {
    CollapsableContent,
    FieldSpan,
    GenericButton,
    Modal,
    BusinessVerticalForm,
  },
  props: ["store"],
  data: () => {
    return {
      businessVerticals: [],
      displayBusinessVerticalModalForm: false,
    };
  },
  methods: {
    async fetchBusinessVerticals() {
      try {
        const { data: { businessVerticals } } = await BackofficeService.getBusinessVerticals(this.store.id);
        this.businessVerticals = businessVerticals;
      } catch (error) {
        console.error(error);
      }
    },
    async onBusinessVerticalCreated() {
      this.displayBusinessVerticalModalForm = false;
      await this.fetchBusinessVerticals();
    }
  },
  async mounted() {
    await this.fetchBusinessVerticals();
  }
};
</script>
