<template>
  <CollapsableContent title="Configuration technique" :open="contentOpen">
    <template #header>
      <div v-if="editable">
        <GenericButton @click="setEditMode" v-if="!editMode">
          Modifier
        </GenericButton>
      </div>
    </template>
    <dl class="bg-white px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Offer cache expiration time") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <FormField
            v-if="editMode"
            id="offer_cache_expiration_time"
            name="offer_cache_expiration_time"
            trailing-add-ons="secondes"
            v-model="offerCacheExpirationTime"
          />
          <p v-else>
            {{ offerCacheExpirationTime }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Matching by categories enabled") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="matching_by_categories_enabled"
            name="matching_by_categories_enabled"
            v-model="matchingByCategoriesEnabled"
            :options="enabledOptions"
          />
          <p v-else>
            {{ matchingByCategoriesEnabled }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Record a sale") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="record_a_sale"
            name="record_a_sale"
            v-model="recordASale"
            :options="enabledOptions"
          />
          <p v-else>
            {{ recordASale }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Send matched contracts without products") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="send_matched_contracts_without_products"
            name="send_matched_contracts_without_products"
            v-model="sendMatchedContractsWithoutProducts"
            :options="enabledOptions"
          />
          <p v-else>
            {{ sendMatchedContractsWithoutProducts }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Automatic matched contract synchronization enabled") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <Selector
            v-if="editMode"
            id="automatic_matched_contract_synchronization_enabled"
            name="automatic_matched_contract_synchronization_enabled"
            v-model="automaticMatchedContractSynchronizationEnabled"
            :options="enabledOptions"
          />
          <p v-else>
            {{ automaticMatchedContractSynchronizationEnabled }}
          </p>
        </dd>
      </div>
    </dl>
    <div v-if="editMode" class="m-3 flex flex-row-reverse gap-4">
      <GenericButton @click="save">
        Enregistrer
      </GenericButton>
      <GenericButton @click="cancelEdit">
        Annuler
      </GenericButton>
    </div>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import FormField  from "@/components/utils/FormField.vue";
import Selector from "@/components/utils/Selector.vue";
import BackofficeService from "@/api/services/backoffice.js";
import { mapActions } from "vuex";

export default {
  components: {
    CollapsableContent,
    GenericButton,
    FormField,
    Selector
  },

  props: ["store"],

  data() {
    return {
      contentOpen: false,
      editMode: false,
      formData: { ...this.store.technicalConfiguration },
      enabledOptions: [
        { label: "true", value: "true" },
        { label: "false", value: "false" },
      ],
    };
  },

  computed: {
    technicalConfiguration() {
      return this.store.technicalConfiguration;
    },
    offerCacheExpirationTime: {
      get() {
        return this.technicalConfiguration.offerCacheExpirationTime;
      },
      set(value) {
        this.formData.offerCacheExpirationTime = value;
      },
    },
    matchingByCategoriesEnabled: {
      get() {
        return this.technicalConfiguration.matchingByCategoriesEnabled;
      },
      set(value) {
        this.formData.matchingByCategoriesEnabled = value;
      },
    },
    recordASale: {
      get() {
        return this.technicalConfiguration.recordASale;
      },
      set(value) {
        this.formData.recordASale = value;
      },
    },
    sendMatchedContractsWithoutProducts: {
      get() {
        return this.technicalConfiguration.sendMatchedContractsWithoutProducts;
      },
      set(value) {
        this.formData.sendMatchedContractsWithoutProducts = value;
      },
    },
    automaticMatchedContractSynchronizationEnabled: {
      get() {
        return this.technicalConfiguration.automaticMatchedContractSynchronizationEnabled;
      },
      set(value) {
        this.formData.automaticMatchedContractSynchronizationEnabled = value;
      },
    },
    editable() {
      return this.store.status === "setup";
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    setEditMode() {
      this.contentOpen = true;
      this.editMode = true;
    },

    cancelEdit() {
      this.editMode = false;
      this.formData = { ...this.technicalConfiguration };
    },

    async save() {
      this.editMode = false;
      try {
        await BackofficeService.updateStore(this.store.id, this.formData);
        await this.notify({
          category: "simple",
          type: "success",
          title: "Mise à jour effectuée",
        });
        this.$emit("storeUpdated");
      } catch (error) {
        console.error(error);
        await this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur est survenue",
          text: error.response?.data?.error || error.message,
        });
      }
    },
  },
};
</script>
