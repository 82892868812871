<template>
  <div>
    <h1 class="text-xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
      Produits assurés
    </h1>
    <ul
      role="list"
      class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <li
        v-for="product in this.plan.product"
        v-bind:key="product.id"
        class="bg-white col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200"
      >
        <div class="flex-1 flex flex-col p-4">
          <img
            class="w-32 h-32 flex-shrink-0 mx-auto"
            v-bind:src="`${product.imageUrl}`"
            alt=""
          />
          <div class="my-4">
            <h3 class="mt-1 text-gray-900 text-md font-bold">
              {{ product.title }}
            </h3>
          </div>
          <div>
            <div
              v-if="product.itemType"
              class="flex flex-row justify-between text-sm"
            >
              <span>Catégorie</span>
              <span class="font-bold">{{ $t(product.itemType) }}</span>
            </div>
            <div class="flex flex-row justify-between text-sm">
              <span>Marque</span>
              <span class="font-bold">{{ product.vendor }}</span>
            </div>
            <div
              v-if="product.model"
              class="flex flex-row justify-between text-sm"
            >
              <span>Modèle</span>
              <span class="font-bold">{{ product.model }}</span>
            </div>
            <div class="flex flex-row justify-between text-sm">
              <span>Prix du produit</span>
              <span class="font-bold">{{ formatPrice(product.price) }}</span>
            </div>
            <div
              v-if="product.referenceId"
              class="flex flex-row justify-between text-sm"
            >
              <span>Numéro de série</span>
              <span class="font-bold truncate">{{ product.referenceId }}</span>
            </div>
            <div class="flex flex-row justify-between text-sm">
              <span>Date d'achat</span>
              <span class="font-bold">{{ product.purchaseDate }}</span>
            </div>
            <div
              v-if="product.insuranceStartDate"
              class="flex flex-row justify-between text-sm"
            >
              <span>Date d'ajout</span>
              <span class="font-bold">{{ product.insuranceStartDate }}</span>
            </div>
            <div
              v-if="product.itemImage"
              class="flex flex-row justify-between text-sm"
            >
              <span>Image</span>
              <a
                class="font-bold underline underline-offset-2"
                :href="product.itemImage"
                >Lien</a
              >
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { formatPrice } from "@/utils/price_formatter";

export default {
  props: ["plan"],

  data() {
    return {};
  },

  computed: {
    planIsActive: function () {
      return this.plan.status === "Active";
    },
  },

  methods: {
    formatPrice
  },
};
</script>
