<template>
  <div>
    <div class="flex flex-col sm:flex-row sm:justify-between items-start mb-6">
      <div class="flex flex-col w-full sm:w-1/2 mt-4 sm:mt-1">
        <div class="flex justify-between">
          <p class="font-bold">Sélectionner une période</p>
          <button
            @click="resetToBeginning"
            class="text-primary-dark underline text-sm"
          >
            Depuis le début
          </button>
        </div>
        <Datepicker
          class="text-sm w-full rounded-md"
          v-model="date"
          range
          multiCalendars
          v-bind:presetRanges="presetRanges"
          autoApply
          v-bind:enableTimePicker="false"
          v-bind:format="datePickerFormat"
          locale="fr"
          :max-date="new Date()"
          :esc-close="false"
        />
      </div>
      <div
        class="w-full mt-4 sm:mt-1 sm:w-1/4"
        v-if="isObjectValid(salesFieldManagers)"
      >
        <p class="font-bold">Sélectionner un animateur</p>
        <SalesFieldManagerSelector
          :salesFieldManagers="salesFieldManagers"
          @update:selectedSalesFieldManager="onSalesFieldManagerChange"
        />
      </div>
    </div>

    <div class="min-h-5 mb-3" v-if="storesView || sellersView">
      <a
        href="#"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        @click="goBack"
      >
        Précédent
      </a>
    </div>
    <CommonAnalyticsTable
      :stats="stats"
      :total="total"
      :currentMonth="currentMonth"
      :statsClickable="organizationsView || storesView"
      :showTargetColumns="true"
      @rowClicked="rowClicked"
      @sortChanged="sortChanged"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";

import CommonAnalyticsTable from "@/components/analytics/retail/utils/CommonAnalyticsTable.vue";
import SalesFieldManagerSelector from "@/components/analytics/retail/utils/SalesFieldManagerSelector.vue";
import { isObjectValid } from "@/utils/validation";
import { mapMutations } from "vuex";
import { formatResponse } from "@/utils/backend_response_formatter";
import { formatAnalyticsDate } from "@/utils/date_formatter";

import "@vuepic/vue-datepicker/dist/main.css";
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  startOfWeek,
  subWeeks,
  endOfWeek,
} from "date-fns";
import { mapState, mapActions } from "vuex";
import AnalyticsService from "@/api/services/analytics";

export default {
  components: {
    Datepicker,
    CommonAnalyticsTable,
    SalesFieldManagerSelector,
  },

  mounted() {
    if (this.signedIn) {
      this.getAnalytics();
    } else {
      this.$router.push("/signin");
    }
  },

  data: () => {
    return {
      selectedOrganizationId: null,
      selectedStoreId: null,
      stats: null,
      total: null,
      sortParams: {
        column: null,
        order: null,
      },
      salesFieldManagerId: null,
      salesFieldManagers: [],
      date: [],
      currentMonth: "",
      presetRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            endOfWeek(new Date(), { weekStartsOn: 1 }),
          ],
        },
        {
          id: 3,
          label: "Mois précédent",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
      ],
      comparedTo: {},
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),

    organizationsView() {
      return !this.storesView && !this.sellersView;
    },

    storesView() {
      return !this.sellersView && this.selectedOrganizationId != null;
    },

    sellersView() {
      return this.selectedStoreId != null;
    },
  },

  created() {
    this.getOrganizations();
    const endDate = endOfMonth(new Date());
    const startDate = startOfMonth(new Date());
    this.date = [startDate, endDate];
  },

  methods: {
    ...mapActions("backoffice", ["getOrganizations"]),
    ...mapMutations("backoffice", ["setIsLoading"]),

    isObjectValid,

    datePickerFormat(date) {
      const startDate = date[0];
      const endDate = date[1];

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate && endDate) {
        if (
          startDate instanceof Date &&
          endDate instanceof Date
        ) {
          if (
            this.compareDate(startDate, startOfMonth(new Date())) &&
            this.compareDate(endDate, endOfMonth(new Date()))
          ) {
            return "Mois en cours";
          }
          if (
            this.compareDate(
              startDate,
              startOfMonth(subMonths(new Date(), 1))
            ) &&
            this.compareDate(endDate, endOfMonth(subMonths(new Date(), 1)))
          ) {
            return "Mois précédent";
          }
          if (
            this.compareDate(
              startDate,
              startOfWeek(new Date(), { weekStartsOn: 1 })
            ) &&
            this.compareDate(
              endDate,
              endOfWeek(new Date(), { weekStartsOn: 1 })
            )
          ) {
            return "Semaine en cours";
          }
          if (
            this.compareDate(
              startDate,
              subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            ) &&
            this.compareDate(
              endDate,
              subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            )
          ) {
            return "Semaine précédente";
          }
        }
      }

      return `Du ${startDay}/${startMonth}/${startYear} au ${endDay}/${endMonth}/${endYear}`;
    },

    stringFormat(string) {
      return (
        string.split("_").join(" ").charAt(0).toUpperCase() +
        string.split("_").join(" ").slice(1)
      );
    },

    compareDate(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },

    async getAnalytics() {
      this.setIsLoading(true);
      try {
        const start = Array.isArray(this.date) && this.date[0] ? formatAnalyticsDate(this.date[0]) : null;
        const end = Array.isArray(this.date) && this.date[1] ? formatAnalyticsDate(this.date[1]) : null;

        const salesFieldManagerId = this.salesFieldManagerId ? this.salesFieldManagerId : "";

        let response;
        if (this.sellersView) {
          response = await AnalyticsService.getSellers(this.selectedStoreId, start, end, this.sortParams, salesFieldManagerId);
        } else if (this.storesView) {
          response = await AnalyticsService.getStores(this.selectedOrganizationId, start, end, this.sortParams, salesFieldManagerId);
        } else {
          response = await AnalyticsService.getOrganizations(start, end, this.sortParams, salesFieldManagerId);
        }

        const formattedResponse = formatResponse(response.data);
        this.stats = formattedResponse.stats;
        this.total = formattedResponse.total;
        this.salesFieldManagers = formattedResponse.salesFieldManagers;
      } catch (error) {
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },

    goBack() {
      if (this.sellersView) {
        this.selectedStoreId = null;
      } else if (this.storesView) {
        this.selectedStoreId = null;
        this.selectedOrganizationId = null;
      }
      this.getAnalytics();
    },

    rowClicked(row) {
      if (this.organizationsView) {
        this.selectedOrganizationId = row.id;
        this.selectedStoreId = null;
      } else if (this.storesView) {
        this.selectedStoreId = row.id;
      }
      this.getAnalytics();
    },

    sortChanged(sortParams) {
      this.sortParams = sortParams;
      this.getAnalytics();
    },

    updateCurrentMonth(endDate) {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      const month = monthNames[endDate.getMonth()];
      const year = endDate.getFullYear();
      this.currentMonth = `${month} ${year}`;
    },

    onSalesFieldManagerChange(salesFieldManagerId) {
      this.salesFieldManagerId = salesFieldManagerId;
      this.getAnalytics();
    },

    resetToBeginning() {
      this.date = null;
      this.getAnalytics();
    },
  },

  watch: {
    date(newDate) {
      if (Array.isArray(newDate) && newDate.length > 0 && newDate[0] && newDate[1]) {
        this.updateCurrentMonth(newDate[1]);
      }
      this.getAnalytics();
    },
  },
};
</script>
