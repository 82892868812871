<template>
  <CollapsableContent title="Informations générales" :open="false">
    <template #header>
      <StoreConfigurationStatusBadge :configuration-status="generalInformations.generalInformationsConfigStatus" />
    </template>
    <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.business_name") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ generalInformations.businessName }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.sales_channel") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ generalInformations.salesChannel }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.billing_address") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ generalInformations.billingAddress }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.revenue_range") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ generalInformations.revenueRange }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.sellers_number") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ generalInformations.sellersNumber }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.store_verticals") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ generalInformations.storeVerticals.join(', ') }}
          </p>
        </dd>
      </div>
    </dl>
    <div class="p-3 flex flex-row-reverse gap-x-4" v-if="isStoreSetup">
      <GenericButton @click="validate">
        Valider
      </GenericButton>
      <GenericButton @click="reject">
        Refuser
      </GenericButton>
    </div>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import StoreConfigurationStatusBadge from "@/components/StoreConfigurationStatusBadge.vue";
import GenericButton from "@/components/utils/GenericButton.vue";

export default {
  components: {
    CollapsableContent,
    StoreConfigurationStatusBadge,
    GenericButton
  },
  props: ["generalInformations", "isStoreSetup"],
  methods: {
    validate() {
      this.$emit("validate");
    },
    reject() {
      this.$emit("reject");
    },
  },
};
</script>
