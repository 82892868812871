<template>
  <CollapsableContent :title="$t('Stores')">
    <template #header>
      <GenericButton @click="$emit('open-modal')">
        {{ $t("Configure a new store") }}
      </GenericButton>
    </template>
    <div class="overflow-x-auto">
      <div class="inline-block min-w-full align-middle">
        <div v-if="anyStoresInformations">
          <div class="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Id") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Name") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Status") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nom du référent</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 text-sm">
                <tr
                  v-for="store in storesInformations"
                  :key="store.id"
                  :class="'hover:bg-gray-100 cursor-pointer'"
                  @click="goTo(store)"
                >
                  <td class="px-3 py-3.5 font-bold">
                    {{ store.id }}
                  </td>
                  <td class="px-3 py-3.5">
                    {{ store.sales_channel }}
                  </td>
                  <td class="px-3 py-3.5">
                    {{ formattedStatus(store) }}
                  </td>
                  <td class="px-3 py-3.5">
                    {{ store.referent_full_name || store.setup_by }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="bg-gray-50 p-6">
            <p>{{ $t("No data to display") }}</p>
          </div>
        </div>
      </div>
    </div>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import GenericButton from "@/components/utils/GenericButton.vue";

export default {
  components: { CollapsableContent, GenericButton },
  props: {
    storesInformations: null,
  },

  data() {
    return {};
  },

  computed: {
    anyStoresInformations() {
      return this.storesInformations?.length > 0;
    },
  },

  methods: {
    formattedStatus(store) {
      if (store.status === "Configuration") {
        return `${store.status} - ${store.setup_status}`;
      }
      return store.status;
    },
    goTo(store) {
      this.$router.push({ path: `/stores/${store.id}` });
    },
  },
};
</script>
