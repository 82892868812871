<template>
  <div class="flex justify-between pb-4">
    <PageHeaderTitle title="Sellers" />
    <button
      class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary uppercase transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none"
      @click="redirectToPlanSales"
    >
      {{ $t("Plan Sales") }}
    </button>
  </div>
  <div>
    <div>
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr class="hidden md:table-row">
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Store") }}
                    </th>
                    <th
                      scope="col"
                      class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                    >
                      {{ $t("Seller") }}
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Sales count") }}
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="(seller, id) in this.sellers" v-bind:key="id">
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ seller.salesChannel }}
                      <div class="md:hidden flex flex-col">
                        <span>{{ seller.name }}</span
                        ><span class="font-bold"
                          >{{ seller.salesCount }} {{ $t("sales") }}</span
                        >
                      </div>
                    </td>
                    <td
                      class="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                    >
                      {{ seller.name }}
                    </td>
                    <td
                      class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ seller.salesCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeaderTitle from "@/components/utils/PageHeaderTitle.vue";
import BackofficeService from "@/api/services/backoffice";

export default {
  components: {
    PageHeaderTitle,
  },
  data: () => {
    return {
      sellers: [],
    };
  },

  mounted() {
    this.getSellers();
  },

  methods: {
    getSellers: async function () {
      const response = await BackofficeService.getSellers();
      this.sellers = response.data.sellers;
    },
    redirectToPlanSales: function () {
      this.$router.push("/plan-sales");
    },
  },
};
</script>
