import { plainAxiosInstance, securedAxiosInstance } from "../axios";

class AuthService {
  signin(user) {
    return plainAxiosInstance.post("/user/sessions", {
      ...user,
    });
  }

  signup(params) {
    return plainAxiosInstance.post("/user/registrations", params);
  }

  resetPassword(resetPasswordToken, payload) {
    return plainAxiosInstance.put(`/user/password_resets/${resetPasswordToken}`, payload);
  }

  signout() {
    return securedAxiosInstance.delete("/user/sessions");
  }
}

export default new AuthService();
