<template>
  <div>
    <div class="mb-4 border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          @click.prevent="selectTab(tab)"
          :class="[
            tab.current
              ? 'border-primary text-primary-dark'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium cursor-pointer',
          ]"
          >{{ tab.displayName }}</a
        >
      </nav>
    </div>

    <div v-for="tab in tabs" :key="tab.name">
      <slot :name="tab.name" v-if="tab.current"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.current = tab.name === selectedTab.name;
      });
    },
  },
};
</script>
