<template>
  <span
    v-bind:class="[
      this.styleToAdd,
      'inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium',
    ]"
  >
    {{ spanText }}
  </span>
</template>

<script>
import { capitalizeFirstLetter } from "@/utils/utils";
import _ from "lodash";

export default {
  props: {
    styleToAdd: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: String,
      required: true,
    },
    translationPrefix: {
      type: String,
      required: false,
      default: "",
    },
  },

  computed: {
    spanText() {
      const textToTranslate = _.compact([
        this.translationPrefix,
        this.value,
      ]).join(".");
      return capitalizeFirstLetter(this.$t(textToTranslate));
    },
  },
};
</script>
