<template>
  <div class="overflow-x-auto">
    <table class="table-auto min-w-full divide-y divide-gray-300 text-xs">
      <thead class="bg-gray-100 border-t border-gray-300">
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            scope="col"
            :class="[
              'px-3 py-2 text-left text-black font-bold tracking-wide whitespace-nowrap w-auto',
              column.numeric ? 'text-right' : ''
            ]"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(row, rowIndex) in data" :key="rowIndex">
          <td
            v-for="(column, cellIndex) in columns"
            :key="cellIndex"
            :class="getCellClass(column)"
            v-html="formatValue(row[column.field], column)"
          ></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/price_formatter";
import { isPresent } from "@/utils/validation"

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatPrice,
    isPresent,
    formatValue(value, column) {
      if (!isPresent(value)) {
        return '—';
      }
      if (typeof value === "number" && column.price) {
        return this.formatPrice(value);
      }
      return value;
    },
    getCellClass(column) {
      let baseClass = "px-3 py-2 whitespace-nowrap font-light";
      if (column.bold) {
        baseClass += " text-black font-semibold";
      }
      if (column.numeric) {
        baseClass += " text-right";
      }
      return baseClass;
    },
  },
};
</script>
