import { createRouter, createWebHistory } from "vue-router";
import Signin from "../views/public/Signin.vue";
import Signup from "../views/public/Signup.vue";
import ForgotPassword from "../views/public/ForgotPassword.vue";
import PasswordReset from "../views/public/PasswordReset.vue";

import store from "../store";
import Analytics from "@/views/analytics/Analytics.vue";
import ProductList from "@/views/products/list/ProductList.vue";
import Sellers from "@/views/sellers/Sellers.vue";
import LeadsList from "@/views/leads/LeadsList.vue";
import ClaimsList from "@/views/claims/ClaimsList.vue";
import ClaimDetails from "@/views/claims/ClaimDetails";
import PlanSales from "../views/plan_sales/PlanSales.vue";
import DiscountList from "@/views/discounts/DiscountList.vue";
import Challenges from "@/views/challenges/Challenges.vue";
import Report from "@/views/report/Report.vue";
import InvoiceDisplay from "@/views/invoices/InvoiceDisplay.vue";
import PlanSaleDetails from "@/views/plan_sales/PlanSaleDetails.vue";
import ProductDetails from "@/views/products/details/ProductDetails.vue";
import StoreDetails from "@/views/stores/StoreDetails.vue";
import Partners from "@/views/partners/Partners.vue";
import OrganizationDetails from "@/views/organizations/OrganizationDetails.vue";

const routes = [
  {
    path: "/signin",
    component: Signin,
    navigation: null,
  },

  {
    path: "/signup",
    component: Signup,
    navigation: null,
  },

  {
    path: "/forgot-password",
    component: ForgotPassword,
  },

  {
    path: "/password-reset/:id",
    component: PasswordReset,
  },

  {
    path: "/",
    component: Analytics,
    meta: { layout: "backoffice-layout", navigation: "Home" },
  },

  {
    path: "/products",
    component: ProductList,
    meta: { layout: "backoffice-layout", navigation: "Products" },
  },

  {
    path: "/products/:id",
    component: ProductDetails,
    meta: { layout: "backoffice-layout", navigation: "Products" },
  },

  {
    path: "/leads",
    component: LeadsList,
    meta: { layout: "backoffice-layout", navigation: "Leads" },
  },

  {
    path: "/claims",
    component: ClaimsList,
    meta: { layout: "backoffice-layout", navigation: "Claim List" },
  },

  {
    path: "/claims/:id",
    component: ClaimDetails,
    meta: { layout: "backoffice-layout", navigation: "Claim List" },
  },

  {
    path: "/plan-sales",
    component: PlanSales,
    meta: { layout: "backoffice-layout", navigation: "Plan Sales" },
  },

  {
    path: "/plan-sales/:id",
    component: PlanSaleDetails,
    meta: { layout: "backoffice-layout", navigation: "Plan Sales" },
  },

  {
    path: "/plan-sales/sellers",
    component: Sellers,
    meta: { layout: "backoffice-layout", navigation: "Plan Sales" },
  },

  {
    path: "/discounts",
    component: DiscountList,
    meta: { layout: "backoffice-layout", navigation: "Promotions" },
  },

  {
    path: "/challenges",
    component: Challenges,
    meta: { layout: "backoffice-layout", navigation: "Challenges" },
  },

  {
    path: "/report",
    component: Report,
    meta: { layout: "backoffice-layout", navigation: "Report" },
  },

  {
    path: "/partners",
    component: Partners,
    meta: { layout: "backoffice-layout", navigation: "Partners" },
  },

  {
    path: "/stores/:id",
    component: StoreDetails,
    meta: { layout: "backoffice-layout", navigation: "Partners" },
  },

  {
    path: "/organizations/:id",
    component: OrganizationDetails,
    meta: { layout: "backoffice-layout", navigation: "Partners" },
  },

  {
    path: "/invoice",
    component: InvoiceDisplay,
    meta: { layout: "backoffice-layout", navigation: "Invoices" },
  },
];

function needsHttpsRedirection() {
  return (
    (process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "demo" ||
      process.env.NODE_ENV === "staging") &&
    location.protocol !== "https:"
  );
}

function redirectHttps() {
  location.replace(
    `https:${location.href.substring(location.protocol.length)}`
  );
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (needsHttpsRedirection()) {
    redirectHttps();
  } else {
    const publicPages = ["/signin", "/signup", "/forgot-password", "/password-reset/:id"];

    const authRequired = !to.matched.some((matched) =>
      publicPages.includes(matched.path)
    );

    const signedIn = store.state.auth.signedIn;

    if (authRequired && !signedIn) {
      next("/signin");
    } else if (signedIn) {
      if (to.path === "/") {
        if (store.getters["auth/isEstalyManager"]) {
          next();
        } else {
          next("/signin");
        }
      } else {
        if (to.path === "/signup" || to.path === "signin") {
          next("/");
        } else {
          next();
        }
      }
    } else {
      next();
    }
  }
});

router;

export default router;
