<template>
  <div class="pb-4">
    <SwitchGroup as="div" class="flex items-center">
      <Switch
        v-model="enabled"
        :class="[
          enabled ? 'bg-primary' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
      <SwitchLabel as="span" class="ml-3 text-sm">
        <span class="font-medium text-gray-700"
          >Select an Organization and a Store</span
        >
      </SwitchLabel>
    </SwitchGroup>
  </div>
  <div class="flex content-center pb-4" v-if="enabled">
    <OrganizationSelector class="w-1/4" />
    <StoreSelector class="ml-4 w-1/4" />
  </div>
  <div>
    <div class="flex items-start">
      <div class="flex flex-col sm:w-1/4 sm:flex-row w-full">
        <Datepicker
          class="text-sm w-full rounded-md"
          v-model="date"
          range
          multiCalendars
          v-bind:presetRanges="presetRanges"
          autoApply
          v-bind:enableTimePicker="false"
          v-bind:format="datePickerFormat"
        />
      </div>
    </div>
    <div class="mt-4 pb-4">
      <dl>
        <div
          class="mt-4 px-4 py-5 bg-white shadow rounded-lg sm:p-6 cursor-pointer hover:shadow-md"
          v-for="(item, name) in this.stats"
          v-bind:key="name"
        >
          <div>
            <div>
              <div class="flex flex-row justify-between">
                <div class="flex flex-row">
                  <p class="text-md font-bold">{{ item.name }}</p>
                </div>
              </div>
              <hr class="my-2 border-gray-300" />
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <dd class="mt-1 text-xl text-gray-900 sm:text-4xl">
              {{ item.stat }} {{ item.currency }}
            </dd>
            <div class="flex flex-row">
              <div
                v-for="(itemDetail, name) in item.details"
                v-bind:key="name"
                class="ml-8"
              >
                <dt class="text-xs sm:text-base text-gray-500">
                  {{ this.stringFormat(name) }}
                </dt>
                <dd class="mt-1 text-xs sm:text-base text-gray-900">
                  {{ itemDetail.stat }} {{ itemDetail.currency }}
                </dd>
              </div>
            </div>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { ref } from "vue";
import { Switch } from "@headlessui/vue";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  startOfWeek,
  subWeeks,
  endOfWeek,
} from "date-fns";
import { mapState, mapActions } from "vuex";
import AnalyticsService from "@/api/services/analytics";
import OrganizationSelector from "@/views/products/list/menu/selectors/OrganizationSelector.vue";
import StoreSelector from "@/views/products/list/menu/selectors/StoreSelector.vue";
import { isStringValid } from "@/utils/validation";
import { formatAnalyticsDate } from "@/utils/date_formatter";

const defaultSelectedOrganization = {
  id: null,
  name: "Choose an organization",
};
const defaultSelectedStore = { id: null, name: "Choose a store" };

export default {
  components: {
    Datepicker,
    OrganizationSelector,
    StoreSelector,
    Switch,
  },

  mounted() {
    if (this.signedIn) {
      this.getAnalytics();
    } else {
      this.$router.push("/signin");
    }
  },

  data: () => {
    return {
      selectedOrganization: defaultSelectedOrganization,
      selectedStore: defaultSelectedStore,
      enabled: ref(false),
      stats: [{ name: "", stat: "", data: [], selected: true }],
      date: [],
      presetRanges: [
        {
          id: 1,
          label: "This Month",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "This Week",
          range: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            endOfWeek(new Date(), { weekStartsOn: 1 }),
          ],
        },
        {
          id: 3,
          label: "Last Month",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          id: 4,
          label: "Last Week",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
        {
          id: 5,
          label: "Since launch",
          range: [new Date(), new Date()],
        },
      ],
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),

    setColor() {
      return (item) => {
        let color;
        let backgroundColor;

        if (item > 0) {
          color = "green";
          backgroundColor = "honeydew"; // Couleur de fond verte claire
        } else if (item < 0) {
          color = "red";
          backgroundColor = "mistyrose"; // Couleur de fond rouge clair
        } else {
          color = "gray";
          backgroundColor = "lightgray"; // Couleur de fond gris clair
        }

        return {
          color: color,
          backgroundColor: backgroundColor,
        };
      };
    },
  },

  created() {
    this.getOrganizations();
    const endDate = endOfMonth(new Date());
    const startDate = startOfMonth(new Date());
    this.date = [startDate, endDate];
  },

  methods: {
    ...mapActions("backoffice", ["getOrganizations"]),

    datePickerFormat: function (date) {
      const startDate = date[0];
      const endDate = date[1];

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate && endDate) {
        if (
          startDate instanceof Date &&
          endDate instanceof Date &&
          startDate.getTime() === startDate.getTime() &&
          endDate.getTime() === endDate.getTime()
        ) {
          if (
            this.compareDate(startDate, startOfMonth(new Date())) &&
            this.compareDate(endDate, endOfMonth(new Date()))
          ) {
            return "This Month";
          }
          if (
            this.compareDate(
              startDate,
              startOfMonth(subMonths(new Date(), 1))
            ) &&
            this.compareDate(endDate, endOfMonth(subMonths(new Date(), 1)))
          ) {
            return "Last Month";
          }
          if (
            this.compareDate(
              startDate,
              startOfWeek(new Date(), { weekStartsOn: 1 })
            ) &&
            this.compareDate(
              endDate,
              endOfWeek(new Date(), { weekStartsOn: 1 })
            )
          ) {
            return "This Week";
          }
          if (
            this.compareDate(
              startDate,
              subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            ) &&
            this.compareDate(
              endDate,
              subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            )
          ) {
            return "Last Week";
          }
        }
      }

      return `From ${startDay}/${startMonth}/${startYear} to ${endDay}/${endMonth}/${endYear}`;
    },

    stringFormat: function (string) {
      return (
        string.split("_").join(" ").charAt(0).toUpperCase() +
        string.split("_").join(" ").slice(1)
      );
    },

    compareDate(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },

    getAnalytics: async function () {
      try {
        const start = formatAnalyticsDate(this.date[0]);
        const end = formatAnalyticsDate(this.date[1]);

        const organizationId = this.enabled
          ? this.$store.state.backoffice.selectedOrganizationId
          : "";
        const storeId = this.enabled
          ? this.$store.state.backoffice.selectedStoreId
          : "";

        const response = await AnalyticsService.getBusinessData(
          organizationId,
          storeId,
          start,
          end
        );
        this.stats = response.data.analytics.map((stat) => {
          stat.selected = false;
          return stat;
        });
        this.stats[0].selected = true;

        if (isStringValid(response.data.launch_date)) {
          this.presetRanges.find((preset) => preset.id === 5).range[0] =
            new Date(response.data.launch_date);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    date: function () {
      this.getAnalytics();
    },

    "$store.state.backoffice.selectedOrganizationId": function () {
      this.getAnalytics();
    },

    "$store.state.backoffice.selectedStoreId": function () {
      this.getAnalytics();
    },

    enabled: function () {
      this.getAnalytics();
    },
  },
};
</script>
