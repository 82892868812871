import { plainAxiosInstance, securedAxiosInstance } from "../axios";

class UserService {
  getUser() {
    return plainAxiosInstance.get("/user");
  }

  forgotPassword(email) {
    return plainAxiosInstance.get("/user/password_resets/new", {
      params: { email },
    });
  }

  updatePassword({ oldPassword, newPassword, newPasswordConfirmation }) {
    return securedAxiosInstance.put("/user/passwords", {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    });
  }
}

export default new UserService();
