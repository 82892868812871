import BackofficeService from "@/api/services/backoffice";
import { isObjectValid } from "@/utils/validation";

const initialState = {
  currentClaim: null,
  availableFilters: {
    status: [],
    organization_id: [],
    store_id: [],
  },
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    doesClaimExists: (state) => {
      return isObjectValid(state.currentClaim);
    },

    approveRepairQuotation: (state) => {
      return state.currentClaim.requiredAction === "approve_repair_quotation";
    },
  },

  mutations: {
    setCurrentClaim(state, currentClaim) {
      state.currentClaim = currentClaim;
    },
  },

  actions: {
    async getClaim({ commit }, claimFriendlyId) {
      try {
        const response = await BackofficeService.getClaimByFriendlyId(claimFriendlyId);
        const claim = response.data;
        commit("setCurrentClaim", claim);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updateClaimStatus({ state, dispatch }, params) {
      await BackofficeService.updateClaimStatus(params);
      dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async uploadClaimInvoice({ state, dispatch }, { repairCost, invoiceFile }) {
      await BackofficeService.uploadClaimInvoice(state.currentClaim, repairCost, invoiceFile);
      dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async uploadClaimQuotation({ state, dispatch }, { repairCost, quotationFile }) {
      await BackofficeService.uploadQuotationInvoice(state.currentClaim, repairCost, quotationFile);
      dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async actionApproveQuotation({ state, dispatch }) {
      await BackofficeService.executeActionOnClaims(state.currentClaim.friendlyId, "approve_repair_quotation");
      dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async actionRefuseQuotation({ state, dispatch }) {
      await BackofficeService.executeActionOnClaims(state.currentClaim.friendlyId, "refuse_repair_quotation");
      dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async actionApproveInvoice({ state, dispatch }) {
      await BackofficeService.executeActionOnClaims(state.currentClaim.friendlyId, "approve_invoice");
      dispatch("getClaim", state.currentClaim.friendlyId);
    },

    async actionRefuseInvoice({ state, dispatch }) {
      await BackofficeService.executeActionOnClaims(state.currentClaim.friendlyId, "refuse_invoice");
      dispatch("getClaim", state.currentClaim.friendlyId);
    },

    reset({ commit }) {
      commit("setCurrentClaim", null);
    },
  },
};
