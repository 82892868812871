import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { securedAxiosInstance, plainAxiosInstance } from "./api/axios";
import VueAxios from "vue-axios";
import "./index.css";
import { createI18n } from "vue-i18n/index";
import { loadLocaleMessages } from "./i18n";

const messages = loadLocaleMessages();
const numberFormats = {
  'fr': {
    currency: {
      style: 'currency', currency: 'EUR', useGrouping: true, currencyDisplay: 'symbol', trailingZeroDisplay: "stripIfInteger"
    },
    decimal: {
      style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
}

const i18n = createI18n({
  locale: "fr",
  messages,
  globalInjection: true,
  numberFormats
});

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance,
});
app.use(i18n);
app.mount("#app");
