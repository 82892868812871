<template>
  <div class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("Product information") }}
      </h3>
    </div>
    <div class="border-t border-gray-200">
      <dl class="bg-white px-4 sm:px-6 py-5 grid grid-cols-2 gap-x-4 gap-y-6">
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Product title") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.title }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Product vendor") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.vendor }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Product model") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <span v-if="product.model">{{ product.model }}</span>
            <span v-else>-</span>
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Product price") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.price }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Product reference ID") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.referenceId }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Product purchase date") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.purchaseDate }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Product added date") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.addedDate }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-bold text-gray-500">{{ $t("Product image") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <img v-if="product.imageUrl" :src="product.imageUrl" :alt="$t('Product image')" class="h-20" />
            <span v-else>-</span>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>
