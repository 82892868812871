<template>
  <CollapsableContent title="KYC" :open="false">
    <template #header>
      <StoreConfigurationStatusBadge :configuration-status="kyc.kycConfigStatus" />
    </template>
    <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.registration_number") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ kyc.registrationNumber }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.billing_address") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ kyc.billingAddress }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.iban") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ kyc.iban }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.rib") }}</dt>
        <dd class="mt-1 text-sm text-gray-900" v-if="kyc.ribFilename">
          <div class="w-0 flex-1 flex">
            <span class="ml-2 flex-1 w-0 truncate">{{ kyc.ribFilename }} </span>
            <a
              class="inline-flex p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary"
              :href="downloadUrl(kyc.ribUrl)"
              target="_blank"
              download
            >
              <EyeIcon class="h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.identityDocument") }}</dt>
        <dd class="mt-1 text-sm text-gray-900" v-if="kyc.identityDocumentFilename">
          <div class="w-0 flex-1 flex">
            <span class="ml-2 flex-1 w-0 truncate">{{ kyc.identityDocumentFilename }} </span>
            <a
              class="inline-flex p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary"
              :href="downloadUrl(kyc.identityDocumentUrl)"
              target="_blank"
              download
            >
              <EyeIcon class="h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.kbis") }}</dt>
        <dd class="mt-1 text-sm text-gray-900" v-if="kyc.kbisFilename">
          <div class="w-0 flex-1 flex">
            <span class="ml-2 flex-1 w-0 truncate">{{ kyc.kbisFilename }} </span>
            <a
              class="inline-flex p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary"
              :href="downloadUrl(kyc.kbisUrl)"
              target="_blank"
              download
            >
              <EyeIcon class="h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </dd>
      </div>
    </dl>
    <div class="p-3 flex flex-row-reverse gap-x-4" v-if="isStoreSetup">
      <GenericButton @click="validate">
        Valider
      </GenericButton>
      <GenericButton @click="reject">
        Refuser
      </GenericButton>
    </div>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import StoreConfigurationStatusBadge from "@/components/StoreConfigurationStatusBadge.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { downloadUrl } from "@/api/axios/index.js";
import { EyeIcon } from "@heroicons/vue/solid";

export default {
  components: {
    CollapsableContent,
    EyeIcon,
    StoreConfigurationStatusBadge,
    GenericButton
  },
  props: ["kyc", "isStoreSetup"],
  methods: {
    downloadUrl,
    validate() {
      this.$emit("validate");
    },
    reject() {
      this.$emit("reject");
    }
  },
};
</script>
