<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5"
    >
      {{ $t(label) }}<span v-if="required" class="text-red-600">*</span>
    </label>
    <div class="relative mt-2">
      <Field
        :id="id"
        :name="name"
        :type="type"
        :class="fieldClass"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <div v-if="trailingAddOns" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span class="text-gray-500 sm:text-sm">{{ trailingAddOns }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Field } from "vee-validate";

export default {
  components: {
    Field,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    fieldClass: {
      type: String,
      default: "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
    },
    trailingAddOns: {
      type: String
    }
  },
  emits: ["update:modelValue"],
};
</script>
