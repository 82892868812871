<template>
  <div class="bg-gray-100 shadow overflow-hidden sm:rounded-lg">
    <div class="flex justify-between items-center px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-bold text-gray-900 cursor-pointer" @click="toggleCollapse">
        {{ title }}
      </h3>
      <div class="flex">
        <slot name="header" />
        <div class="w-10 ml-2 cursor-pointer" @click="toggleCollapse">
          <ChevronDownIcon v-if="collapsed" />
          <ChevronUpIcon v-else />
        </div>
      </div>
    </div>
    <div v-if="!collapsed" class="flex flex-col border-t border-gray-200 bg-white">
      <slot />
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronDownIcon, ChevronUpIcon },

  props: {
    open: {
      type: Boolean,
      default: true,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      collapsed: false,
    };
  },

  methods: {
    toggleCollapse: function () {
      this.collapsed = !this.collapsed;
    },
  },

  watch: {
    open: {
      immediate: true,
      handler() {
        this.collapsed = !this.open;
      },
    },
  },
};
</script>
