<template>
  <CollapsableContent title="Sav et réparations" :open="false">
    <template #header>
      <StoreConfigurationStatusBadge :configuration-status="afterSalesService.afterSalesServiceConfigStatus" />
    </template>

    <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.repair_workshop") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <span v-if="afterSalesService.repairWorkshop != null">
            {{ $t(`${afterSalesService.repairWorkshop}`) }}
          </span>
        </dd>
      </div>
      <div v-if="afterSalesService.repairWorkshop">
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.repair_workshop_email") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ afterSalesService.repairWorkshopEmail }}
          </p>
        </dd>
      </div>
    </dl>
    <div class="p-3 flex flex-row-reverse gap-x-4" v-if="isStoreSetup">
      <GenericButton @click="validate">
        Valider
      </GenericButton>
      <GenericButton @click="reject">
        Refuser
      </GenericButton>
    </div>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import StoreConfigurationStatusBadge from "@/components/StoreConfigurationStatusBadge.vue";
import GenericButton from "@/components/utils/GenericButton.vue";

export default {
  components: {
    CollapsableContent,
    StoreConfigurationStatusBadge,
    GenericButton
  },
  props: ["afterSalesService", "isStoreSetup"],
  methods: {
    validate() {
      this.$emit("validate");
    },
    reject() {
      this.$emit("reject");
    }
  },
};
</script>
