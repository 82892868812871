<template>
  <div>
    <TabNavigation :tabs="tabs">
      <template #suivi_des_remunerations>
        <BusinessAnalytics />
      </template>
      <template #suivi_des_ventes>
        <RetailAnalytics />
      </template>
    </TabNavigation>
  </div>
</template>

<script>
import TabNavigation from "@/components/utils/TabNavigation.vue";
import BusinessAnalytics from "@/components/analytics/business/Analytics.vue";
import RetailAnalytics from "@/components/analytics/retail/Analytics.vue";

export default {
  components: {
    TabNavigation,
    BusinessAnalytics,
    RetailAnalytics,
  },
  data: () => ({
    tabs: [
      { name: "suivi_des_remunerations", displayName: "Suivi des rémunérations", current: true },
      { name: "suivi_des_ventes", displayName: "Suivi des ventes", current: false },
    ],
  }),
};
</script>
