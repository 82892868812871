<template>
  <span
    v-if="configurationStatus"
    :class="status[configurationStatus]"
  >
    {{ $t(`store.configuration_status.${configurationStatus}`) }}
  </span>
</template>

<script>
export default {
  props: {
    configurationStatus: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      status: {
        to_be_completed: "inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600",
        pending_validation: "inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800",
        validated: "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700",
        rejected: "inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700",
      },
    };
  },

};
</script>
