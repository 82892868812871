<template>
  <div>
    <Datepicker
      v-bind:placeholder="this.placeholder"
      class="text-sm w-full rounded-md"
      v-model="date"
      range
      multiCalendars
      v-bind:presetRanges="presetRanges"
      autoApply
      v-bind:enableTimePicker="false"
      v-bind:format="datePickerFormat"
      locale="fr"
      :max-date="new Date()"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";
import { formatAnalyticsDate } from "@/utils/date_formatter";

export default {
  components: { Datepicker },

  props: ["placeholder"],

  watch: {
    date: function (newValue) {
      if (newValue) {
        const startDate = formatAnalyticsDate(newValue[0]);
        const endDate = formatAnalyticsDate(newValue[1]);
        this.$emit(
          "newDateSelected",
          startDate.toDateString(),
          endDate.toDateString()
        );
      } else {
        this.$emit("newDateSelected", null, null);
      }
    },
  },

  data: () => {
    return {
      date: [],
      presetRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            endOfWeek(new Date(), { weekStartsOn: 1 }),
          ],
        },
        {
          id: 3,
          label: "Mois précédent",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
      ],
      comparedTo: {},
    };
  },

  methods: {
    datePickerFormat: function (date) {
      const startDate = date[0];
      const endDate = date[1];

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate && endDate) {
        if (
          startDate instanceof Date &&
          endDate instanceof Date &&
          startDate.getTime() === startDate.getTime() &&
          endDate.getTime() === endDate.getTime()
        ) {
          if (
            this.compareDate(startDate, startOfMonth(new Date())) &&
            this.compareDate(endDate, endOfMonth(new Date()))
          ) {
            return "Mois en cours";
          }
          if (
            this.compareDate(
              startDate,
              startOfMonth(subMonths(new Date(), 1))
            ) &&
            this.compareDate(endDate, endOfMonth(subMonths(new Date(), 1)))
          ) {
            return "Mois précédent";
          }
          if (
            this.compareDate(
              startDate,
              startOfWeek(new Date(), { weekStartsOn: 1 })
            ) &&
            this.compareDate(
              endDate,
              endOfWeek(new Date(), { weekStartsOn: 1 })
            )
          ) {
            return "Semaine en cours";
          }
          if (
            this.compareDate(
              startDate,
              subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            ) &&
            this.compareDate(
              endDate,
              subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            )
          ) {
            return "Semaine précédente";
          }
        }
      }

      return `Du ${startDay}/${startMonth}/${startYear} au ${endDay}/${endMonth}/${endYear}`;
    },

    compareDate(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },
  },
};
</script>
