<template>
  <div class="pt-6 md:pt-0 relative overflow-x-auto">
    <Table
      :columns="tableColumns"
      :data="formattedData"
    />
  </div>
</template>

<script>
import Table from "@/components/utils/Table.vue";
import { formatPrice } from "@/utils/price_formatter";

export default {
  components: {
    Table,
  },
  props: ["challenges"],
  
  computed: {
    tableColumns() {
      return [
        { field: 'id', label: 'ID', numeric: true, bold: true },
        { field: 'startDate', label: 'Début' },
        { field: 'endDate', label: 'Fin' },
        { field: 'salesTargets', label: 'Objectifs de vente', price: true },
        { field: 'participants', label: 'Participants' },
      ];
    },
    formattedData() {
      return this.challenges.map((challenge) => ({
        id: challenge.id,
        startDate: challenge.startDate,
        endDate: challenge.endDate,
        salesTargets: this.formatSalesTargets(challenge.salesTargets),
        participants: this.getFormattedOrganizations(challenge),
      }));
    },
  },

  methods: {
    formatPrice,
    
    formatStartEndDates(challenge) {
      return `${challenge.startDate}${
        window.innerWidth < 768 ? " au " + challenge.endDate : ""
      }`;
    },

    formatSalesTargets(salesTargets) {
      return salesTargets
        .map(
          (item) =>
            `• Target: ${item.target} | Reward: ${this.formatPrice(item.reward)}`
        )
        .join("<br/>");
    },

    getFormattedOrganizations(challenge) {
      return challenge.organizations.map((org) => org.name).join(" - ");
    },
  },
};
</script>
