import { isObjectValid } from "@/utils/validation";
import { format } from "date-fns";

export function formatDate(date, locale) {
  let formattedDate = null;
  if (isObjectValid(date)) {
    formattedDate = new Date(date).toLocaleString(locale);
  }
  return formattedDate;
}

export function formatAnalyticsDate(date) {
  let formattedDate = null;
  if (isObjectValid(date)) {
    formattedDate = format(new Date(date), 'yyyy-MM-dd');
  }
  return formattedDate;
}
